import { injectGlobal } from "@emotion/css";
import type { AppProps } from "next/app";
import Head from "next/head";
import { NextIntlProvider } from "next-intl";

import { colors } from "../theme/colors";

injectGlobal`
  * {
    box-sizing: border-box;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  body {
    background: linear-gradient(
      to right,
      ${colors.darkBlue},
      ${colors.lightBlue}
    );
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    line-height: 1.5;
  }
`;

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <NextIntlProvider messages={pageProps.messages}>
      <Head>
        <meta
          key="viewport"
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />
      </Head>
      <Component {...pageProps} />
    </NextIntlProvider>
  );
}

export default MyApp;
